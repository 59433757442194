import { useEffect } from "react";
import logo from "../assets/images/logo.png";
import businessmanplanning from "../assets/images/businessmanplanning.png";
import signpage from "../assets/images/signpage.png";
import WavyBus from "../assets/images/WavyBus.png";
import { t } from 'i18next';
import { Link, useNavigate } from "react-router-dom";
import { apiLogin } from "../service/ApiServices";
import { getUserProfile } from "../storage/storage";
export const HomeScreen = () => {
  const navigate = useNavigate();
  const Login = (response: any) => {
    apiLogin("edgar1", "edgar@123").then(response)
  }
  useEffect(() => {
    const response = getUserProfile();
    if (response) {
      if (response.userId) navigate("/bookGroupRoom");
    }
  }, []);

  return (
    <div className="bgImage" id="homePage">
      <div className="pageContent">
        <img src={logo} alt='' className="logo mb-4" />
        <h3 className="heading">{t("videoChatStudying")}</h3>
        <h5 className="heading2">
          {t('findStudyForChallenges')}
          <br />
          {t("todayMakeVideoChatSimple")}
        </h5>
        <Link to="/login" className="link">
          <button className="button getStarted" onClick={Login}>{t("getStartedFree")}</button>
        </Link>
        <div className="linksDiv">
          <div className="sideImages">
            <img className="LinkImages" src={signpage} alt="" />
            <p>{t("createAccount")}</p>
          </div>

          <div className="middleImg">
            <img className="LinkImages" src={businessmanplanning} alt="" />
            <p>{t("joinStudySession")}</p>
          </div>

          <div className="sideImages">
            <img className="LinkImages" src={WavyBus} alt="" />
            <p>{t("getPairedUpToday")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HomeScreen;
