export const SIGN_IN = "api/users/loginByGoogleId";
export const GET_ALL_ROOM_BY_GRADE_ID = "api/room/getAllRoomByGradeId";
export const GET_ALL_BOOKING_BY_USER = "api/userbooking/getAllBookingByUser";
export const GET_CURRENT_BOOKING_BY_USER =
  "api/userbooking/getCurrentBookingByUser";
export const POST_USER_PROFILE = "api/users/createUserProfile";
export const UPDATE_USER_BOOKING_STATUS =
  "api/userbooking/updateUserBookingStatusById";
export const CREATE_LOGIN_SESSION = "api/room/createJoinSession";
export const GET_INVITE_CODE = "api/room/UserBookingByInvitecode";
export const GET_TWILIO_ACCOUNT = "api/userbooking/getAccessTwilioToken";
export const Create_Room = "api/room/createRoom";
export const POST_JOINED_SESSION = "api/room/createJoinedSession";
export const GET_USER_PROFILE = "api/users/getUsersProfilePicture";
export const UPDATE_USER_PROFILE = "api/users/updateUserProfileById";
export const LOGIN_API = "api/usersAdmin/loginByUserAdmin"
