import { Route, Routes } from 'react-router-dom'
import HomeScreen from '../pages/HomeScreen'
import MyProfile from '../pages/MyProfileScreen'
import BookGrouproomScreen from '../pages/BookGrouproomScreen'
import SecurityScreen from '../pages/SecurityScreen'
import MyBookingsScreen from '../pages/MyBookingsScreen'
import EnteringRoomScreen from '../pages/EnteringRoomScreen'
import BookNextRoomScreen from '../pages/BookNextRoomScreen'
import GoogleLogin from '../pages/GoogleLogin'
import CreateGroupRoom from '../pages/createGroupRoom'
import UpdateProfileScreen from '../pages/UpdateProfileScreen'

const RoutesComponent = () => {
  return (
    <Routes>
      <Route path="/" element={<HomeScreen />} />
      <Route path="/login" element={<GoogleLogin />} />
      <Route path="/homeScreen" element={<HomeScreen />} />
      <Route path="/myProfile" element={<MyProfile />} />
      <Route path="/updateProfile" element={<UpdateProfileScreen />} />
      <Route path="/bookGroupRoom" element={<BookGrouproomScreen />} />
      <Route path="/createGroupRoom" element={<CreateGroupRoom />} />
      <Route path="/security" element={<SecurityScreen />} />
      <Route path="/myBookings" element={<MyBookingsScreen />} />
      <Route path="/enteringRoom" element={<EnteringRoomScreen />} />
      <Route path="/bookNextRoom" element={<BookNextRoomScreen />} />
    </Routes>
  )
}

export default RoutesComponent
