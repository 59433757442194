type Props = {
  question: string
  data: Array<any>
  value: any
  onpress: (event: any) => void
  userProfile?: any
  isActive?: any
}

const Options = ({
  question,
  data,
  value,
  onpress,
  userProfile,
}: Props) => {
  return (
    <>
      <p className="questionsTxt">{question}</p>
      <div className="optionsOuter">
        {data.map((item: any) => {
          return (
            <button
              type="button"
              className="options"
              style={{
                background:
                  value?.placeholder === item.placeholder
                    ? '#d6d6d6'
                    : '#ffffff',
              }}
              onClick={() => onpress(item)}
            >
              {item.placeholder}
            </button>
          )
        })}
      </div>
    </>
  )
}
export default Options
