import { socialIcons } from "../constants/Constants";
import { t } from 'i18next';

function Footer() {
  return (
    <footer id="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-5 contactUsContainer">
            <h4 className="contactUsText">{t("contactUs")}</h4>
            <p className="address">{t("edgarEmail")}</p>
          </div>
          <div className="col-md-3 followUsContainer">
            <h4 className="contactUsText">{t("followUs")}</h4>
            <div className="d-flex">
              {socialIcons.map((socialIcon: any, index: number) => {
                return (
                  <a href={socialIcon.route} target="_blank" key={index} rel="noreferrer">
                    <img
                      src={socialIcon.image}
                      alt="Icon"
                      className={socialIcon.class}
                    />
                  </a>
                );
              })}
            </div>
          </div>
          <div className="col-md-4 inputMainContainer">
            <h4 className="contactUsText">{t("subscribe")}</h4>
            <div className="inputContainer">
              <input type="text" placeholder={t("emailAddress")??""} />
              <button className="submit">
                <p>{t("submit")}</p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
