import React, { useEffect, useState } from "react";
import Participant from "./Participant";
import useRoom from "../useRoom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMicrophone,
  faMicrophoneSlash,
  faVideo,
  faVideoSlash
} from "@fortawesome/free-solid-svg-icons";
//@ts-ignore
import Loader from "react-js-loader";
import Icon from "../assets/images/callEndIcon.png";
import FlipBack from "../assets/images/flipBack.png";
import FlipRight from "../assets/images/FlipRight.png";
import CommonModal from "./CommonModal";
import inviteCodeIcon from "../assets/images/inviteCodeIcon.png";
import { DISCONNECT_ROOM } from "../constants/Constants";
import Logo from "../assets/images/logo.png";
import { t } from "i18next";
type Props = {
  createGroupRoomScreen: any;
  token: any;
  identity: any;
  roomName: any;
  onDisconnected: any;
  copyText: any;
  inviteCode: any;
  userLogin: any;
  remainingSessionTime: any;
};
function Room({
  createGroupRoomScreen,
  token,
  identity,
  roomName,
  onDisconnected,
  copyText,
  inviteCode,
  userLogin,
  remainingSessionTime
}: Props) {
  const {
    room,
    error,
    connectRoom,
    disconnectRoom,
    localParticipant,
    remoteParticipants,
    isCameraOn,
    toggleCamera,
    isMicrophoneOn,
    toggleMicrophone
  }: any = useRoom();
  const [isFlip, setIsFlip] = useState(false);
  const [fullScreen, setFullScreen] = useState();
  const [remainingTime, setRemainingTime] = useState<any>(
    localStorage.getItem("remainingSessionTime") ?? remainingSessionTime
  );
  const [alertModal, setAlertModal] = useState<any>({
    show: false,
    msg: ""
  });
  const [isParticipants, setIsParticipants] = useState(0);
  const getParticipants = (remainingTime: any) => {
    if (createGroupRoomScreen == "true") {
      if (remoteParticipants.length == 0) {
        if (remainingTime == 50) {
          setAlertModal({
            show: true,
            msg: "No other Participants found"
          });
        }
      }
    }
  };
  const updateGridLayout = () => {
    const videoGrid = document.querySelector<HTMLElement>(
      ".mainVideoContainer"
    );
    const videoContainer = document.querySelectorAll<HTMLElement>(
      ".video-container"
    );
    if (videoGrid) {
      // const NumItems = videoGrid?.childElementCount;
      // const numColumns = Math.ceil(Math.sqrt(NumItems));
      if (remoteParticipants.length === 3) {
        for (let i = 0; i <= videoContainer.length - 1; i++) {
          videoContainer[i].style.flexBasis = "45%";
        }
      } else if (remoteParticipants.length === 6) {
        for (let i = 0; i <= videoContainer.length - 1; i++) {
          videoContainer[i].style.flexBasis = "23%";
        }
      } else if (remoteParticipants.length === 7) {
        for (let i = 0; i <= videoContainer.length - 1; i++) {
          videoContainer[i].style.flexBasis = "23%";
        }
      } else if (remoteParticipants.length === 8) {
        for (let i = 0; i <= videoContainer.length - 1; i++) {
          videoContainer[i].style.flexBasis = "19%";
        }
      } else if (remoteParticipants.length === 9) {
        for (let i = 0; i <= videoContainer.length - 1; i++) {
          videoContainer[i].style.flexBasis = "19%";
        }
      }
    }
  };
  const renderParticipants = () => {
    if (fullScreen) {
      return (
        <div
          className={`fullScreen ${
            isFlip ? "localParticipantVideo" : "localParticipantSwitch"
          }`}
          onClick={() => setFullScreen(undefined)}
          style={{ flexBasis: "100%" }}
        >
          <Participant participant={fullScreen} userDetails={userLogin} />
        </div>
      );
    }
    return (
      <>
        <div
          className={`video-container ${
            isFlip ? "localParticipantSwitch" : "localParticipantVideo"
          }`}
        >
          <p className="userName" style={{ zIndex: alertModal.show ? 0 : 1 }}>
            {localParticipant?.identity + " (You)"}
          </p>
          <div
            onClick={() => {
              setFullScreen(localParticipant);
            }}
            style={{ flexBasis: "100%" }}
          >
            <Participant
              participant={localParticipant}
              userDetails={userLogin}
            />
          </div>
        </div>
        <>
          {remoteParticipants.map((p: any) => (
            <div className="video-container">
              <p
                className="userName"
                style={{ zIndex: alertModal.show ? 0 : 1 }}
              >
                {p.identity}
              </p>
              <div
                onClick={() => {
                  setFullScreen(p);
                  setIsFlip(true);
                }}
                style={{ flexBasis: "100%" }}
              >
                <Participant participant={p} userDetails={userLogin} />
              </div>
            </div>
          ))}
        </>
      </>
    );
  };
  useEffect(() => {
    getParticipants(remainingTime);
    if (!room && token && roomName) {
      connectRoom({
        token,
        options: { name: roomName, dominantSpeaker: true }
      });
      return () => disconnectRoom();
    }
  }, [connectRoom, remainingTime, disconnectRoom, room, roomName, token]);
  useEffect(() => {
    updateGridLayout();
  }, [remoteParticipants]);
  React.useEffect(() => {
    remainingTime > 0 &&
      setTimeout(() => {
        setRemainingTime(remainingTime - 1);
        localStorage.setItem(
          "remainingSessionTime",
          (remainingTime - 1).toString()
        );
      }, 1000 * 60);
    if (remainingTime == 0) {
      disconnectRoom();
      onDisconnected && onDisconnected();
    }
  }, [remainingTime]);
  useEffect(() => {
    if (isParticipants == 0) setIsParticipants(remoteParticipants);
    else if (remoteParticipants.length > 0)
      setIsParticipants(remoteParticipants);
    else {
      disconnectRoom();
      onDisconnected && onDisconnected();
    }
  }, [remoteParticipants]);
  const [isInviteCodeCard, setInviteCodeCard] = useState(true);
  if (error) return <div>Error: {error.message}</div>;
  // connected
  if (room)
    return (
      <>
        <div className="mainVideoContainer">
          <div className="buttonContainer">
            {inviteCode && isInviteCodeCard && (
              <div className="videoLinkCard">
                <i
                  className="fa fa-times crossIcon"
                  onClick={() => {
                    setInviteCodeCard(false);
                  }}
                ></i>
                <h4>{t("YourRoomIsReady")}</h4>
                <p>{t("OrShareThisMeetingLinkWithOthersThatYouWantInRoom")}</p>
                <div className="inviteCodeBox">
                  <p>{inviteCode} </p>
                  <i className="fa fa-clone copyIcon" onClick={copyText}></i>
                </div>
              </div>
            )}
            <div className="timeContainer">
              <p>
                <img src={Logo} alt="Logo" />
                EDGAR
              </p>
              {/* <p>{sessionTime.slice(0, 5)}</p> */}
              <p>{remainingTime} min left</p>
            </div>
            <button
              className="iconContainer"
              onClick={() => setIsFlip(!isFlip)}
            >
              {isFlip ? (
                <img
                  src={FlipRight}
                  alt=""
                  width="20px"
                  style={{ filter: "invert(100%)" }}
                />
              ) : (
                <img
                  src={FlipBack}
                  alt=""
                  width="20px"
                  style={{ filter: "invert(100%)" }}
                />
              )}
            </button>
            <button
              onClick={() => toggleMicrophone()}
              className="iconContainer"
            >
              {isMicrophoneOn ? (
                <FontAwesomeIcon icon={faMicrophone} />
              ) : (
                <FontAwesomeIcon icon={faMicrophoneSlash} />
              )}
            </button>
            <button
              id="leaveRoom"
              className="iconContainer leaveCall"
              onClick={() => {
                disconnectRoom();
                onDisconnected && onDisconnected();
              }}
            >
              <img src={Icon} alt="" />
            </button>
            <button
              onClick={() => toggleCamera()}
              className="iconContainer video-icon"
            >
              {isCameraOn ? (
                <FontAwesomeIcon icon={faVideo} />
              ) : (
                <FontAwesomeIcon icon={faVideoSlash} />
              )}
            </button>
            <div
              className="iconContainer"
              onClick={() => {
                setInviteCodeCard(true);
              }}
            >
              {" "}
              <img src={inviteCodeIcon} alt="" className="inviteCodeIcon" />
            </div>
          </div>
          <div
            className={`videoOuter ${
              remoteParticipants.length === 1
                ? "centeredVideo"
                : remoteParticipants.length >= 3
                ? "mainVideoContainer"
                : "startVideo"
            }`}
          >
            {renderParticipants()}
          </div>
        </div>
        {alertModal.show && (
          <CommonModal
            title={alertModal.msg}
            onClose={() => {
              setAlertModal({ show: false, msg: "" });
              localStorage.setItem("internetLost", "true");
              disconnectRoom();
              onDisconnected && onDisconnected();
            }}
            list={alertModal.list}
            className={alertModal.className}
            buttonText={DISCONNECT_ROOM}
          />
        )}
      </>
    );
  else
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          width: "100%"
        }}
      >
        <Loader type="spinner-circle" bgColor={"#005190"} size={50} />
      </div>
    );
  // return "connecting...";
}
export default Room;
