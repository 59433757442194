import { useEffect, useState } from "react";
import LockSign from "../assets/images/LockIcon.png";
import Navbar from "../components/Navbar";
import { useNavigate } from "react-router-dom";
import ButtonComponent from "../components/Button";
import OtpInputComponent from "../components/OtpInput";
import DatePickerComponent from "../components/DatePicker";
import ClassJoiner from "../components/ClassJoiner";
//@ts-ignore
import Loader from "react-js-loader";
import { FOR_BOOKING_POINTS, FOR_FIND_CODE, OK } from '../constants/Constants';
import moment from "moment";
import {
  apigetAllRoomByGradeId,
  apipatchUserBookingStatus,
  apigetInviteCode,
  apiPostCreateJoinSession,
} from "../service/ApiServices";
import { getUserProfile, saveBookingRoom } from "../storage/storage";
import Footer from "../components/Footer";
import CommonModal from "../components/CommonModal";
import { t } from "i18next";
import getDate from "../useDate";


export const BookGrouproomScreen = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isJoinRoomLoader, setIsJoinRoomLoader] = useState(false);
  const [isRoomStarted] = useState<boolean>(false);
  // const [value, updateValue] = useState<any>();
  const [isDate, setDate] = useState<any>(new Date());
  const [currentDate, setCurrentDate] = useState<any>(new Date());

  const [roomData, setRoomData] = useState<any>([]);
  const [selectedId, setSelectedId] = useState<any>(-1);
  const [userProfile, setUserProfile] = useState<string>("");
  const [alertModal, setAlertModal] = useState<any>({
    show: false,
    msg: "",
    list: [],
    className: "",
  });
  const [isResetOtp, setIsResetOtp] = useState(false);
  const [currentTime, setCurrentTime] = useState<any>(
    async() => {
     return moment(await getDate()).format("YYYY-MM-DD HH:mm:ss")
    }
    
  );


  function formatTime(timeString: any) {
    const [hourString, minute] = timeString.split(":");
    const hour = +hourString % 24;
    return (hour % 12 || 12) + ":" + minute + (hour < 12 ? ' a.m.' : ' p.m.');
  }
  let oneTimeGetRoom = false;
  const getRoom = async() => {
    const res = getUserProfile();
    if (res) setUserProfile(res);
    if (res == null) {
      setUserProfile(res);
      navigate("/myProfile");
      alert(t("CompleteYourProfileIst"));
    }
    else if (!res.grade && !res.language && !res.gender && !res.userId) {
      navigate("/myProfile");
      alert(t("CompleteYourProfileIst"));
    }
    else { }
    let SelectedDate = moment(isDate).format("YYYY-MM-DD");

    // const d = new Date();
    // let hour = d.getHours();
    // let min = d.getMinutes();
    // let time = hour + ":" + min + ":00";
    const date = await getDate()
    setCurrentDate(date)
    apigetAllRoomByGradeId(
      res,
      SelectedDate ?? "",
      moment(isDate).format("YYYY-MM-DD") === moment(date).format("YYYY-MM-DD")? moment(date).format('HH:mm:ss'):  "00:00:00",
      localStorage.getItem("token")!
    )
      .then((response) => {
        if (
          response ==
          `SyntaxError: Unexpected token 'I', "Invalid Token" is not valid JSON`
        ) {
          localStorage.clear()
          navigate("/login")
        }
        else {
          setCurrentTime(moment(date).format("YYYY-MM-DD HH:mm:ss"))
          setRoomData(response);
          setIsLoading(false);
          saveBookingRoom(response, res?.token);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (oneTimeGetRoom === false) {
      getRoom();
    }
    oneTimeGetRoom = true;

    // getDate().then((date) => {
      
    //   const inputDate = new Date()
    //   const year = inputDate.getFullYear();
    //   const month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
    //   const day = inputDate.getDate().toString().padStart(2, '0');
    //   const hours = inputDate.getHours().toString().padStart(2, '0');
    //   const minutes = inputDate.getMinutes().toString().padStart(2, '0');
    //   const seconds = inputDate.getSeconds().toString().padStart(2, '0');
    //   const milliseconds = inputDate.getMilliseconds();

    //   const timeZoneOffset = inputDate.getTimezoneOffset();
    //   const offsetHours = Math.floor(Math.abs(timeZoneOffset) / 60).toString().padStart(2, '0');
    //   const offsetMinutes = (Math.abs(timeZoneOffset) % 60).toString().padStart(2, '0');
    //   const offsetSign = timeZoneOffset < 0 ? '+' : '-';

    //   const isoFormattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${offsetSign}${offsetHours}:${offsetMinutes}`;
    //   console.log("date", moment(date).format('YYYY-MM-DDTHH:mm:ssZ'), isoFormattedDate)
    //   if (moment(date).format('YYYY-MM-DDTHH:mm:ssZ') === isoFormattedDate) {
    //     alert('match')
    //   } else {
    //     alert('bug')
    //   }
    // }).catch()

  }, [isDate]);
  function onChange(date: any): void {
    // updateValue(date);
  }

  const getRemainingTime = (
    sessiontime: any,
    currenttime: any
  ): { expireTime: number; showingTime: string } => {
    let SelectedDate = moment(isDate).format("YYYY-MM-DD");
    let sessionTime = moment(SelectedDate + sessiontime, "YYYY-MM-DD HH:mm:ss");
    var duration: any = moment.duration(sessionTime.diff(currenttime));
    var hours = parseInt(duration.asHours());
    var minutes = parseInt(duration.asMinutes()) % 60;
    var time =
       hours > 0 && hours < 24  ? hours + t('HourAnd') + minutes + " min" : hours > 23 ? (hours/24).toFixed(0) + t('DaysLeft')  :  minutes + "min";
    localStorage.setItem("time", time);
    return { expireTime: minutes, showingTime: time };
  };

  const getRemainingTimeInMillSec = (
    sessiontime: any,
    currenttime: any
  ): number => {
    let SelectedDate = moment(isDate).format("YYYY-MM-DD");
    let sessionTime = moment(SelectedDate + sessiontime, "YYYY-MM-DD HH:mm:ss");
    var duration: any = moment.duration(sessionTime.diff(currenttime));
    var seconds = parseInt(duration.asSeconds());
    return (seconds)
  };

  const getTimeFormat =  (sessiontime: any) => {
    let SelectedDate = moment(currentDate).format("YYYY-MM-DD");
    let currenttime = moment(currentDate).format("YYYY-MM-DD HH:mm:ss");
    let sessionTime = moment(SelectedDate + sessiontime, "YYYY-MM-DD HH:mm:ss");
    var duration: any = moment.duration(sessionTime.diff(currenttime));
    return duration.asMilliseconds();
  };
  const fiveMin = 5 * 60 * 1000;
  const cancelStatus = (item: any) => {
    setSelectedId(item);
    apipatchUserBookingStatus(
      userProfile,
      item,
      "0",
      localStorage.getItem("token")!
    ).then((response) => {
      if (
        response ==
        `SyntaxError: Unexpected token 'I', "Invalid Token" is not valid JSON`
      ) {
        localStorage.clear()
        navigate("/login")
      }
      else {
        setSelectedId(-1);
        getRoom();
      }
    });
  };
  const bookedStatus = (item: any) => {
    setSelectedId(item);
    apipatchUserBookingStatus(
      userProfile,
      item,
      "1",
      localStorage.getItem("token")!
    ).then((response) => {
      if (
        response ==
        `SyntaxError: Unexpected token 'I', "Invalid Token" is not valid JSON`
      ) {
        localStorage.clear()
        navigate("/login")
      }
      else {
        setSelectedId(-1);
        getRoom();
      }
    });
  };
  const joinRoom = async (item: any) => {
    let currentTime = moment(await getDate()).format("HH:mm");
    apiPostCreateJoinSession(
      userProfile,
      item?.id,
      "5",
      currentTime,
      "false",
      localStorage.getItem("token")!
    ).then((response) => {
      if (
        response ==
        `SyntaxError: Unexpected token 'I', "Invalid Token" is not valid JSON`
      ) {
        localStorage.clear()
        navigate("/login")
      }
      else {
        if (response?.session?.msg == "you missed the session") {
          setAlertModal({ show: true, msg: response?.session?.msg, list: [] });
          getRoom();
        } else {
          localStorage.setItem("sessionCode", response.session?.session_code);
          localStorage.setItem("identity", response?.session?.identity)
          localStorage.setItem("createGroup", "false");
          localStorage.setItem("id", item.id)
          localStorage.setItem("RemainingTime", response?.session?.RemainingTime);
          localStorage.setItem("remainingSessionTime", "55");
          const timeMilliSec = getRemainingTimeInMillSec(
            item.sessiontime,
            moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
          )
          navigate("/enteringRoom", {
            state: {
              itemId: response?.session?.session_code ?? '',
              createGroupRoom: "false",
              identity: response?.session?.identity ?? '',
              roomId: item.id ?? '',
              sessionTime: item?.sessiontime ?? '',
              remainingTime: timeMilliSec ?? '',
            },
          });
        }
      }
    });
  };
  const joinGroupRoom = () => {
    if (otp.length < 4) return;
    setIsJoinRoomLoader(true);
    apigetInviteCode(otp, userProfile, localStorage.getItem("token")!).then(
      (res) => {
        if (
          res ==
          `SyntaxError: Unexpected token 'I', "Invalid Token" is not valid JSON`
        ) {
          localStorage.clear()
          navigate("/login")
        }
        else if (res?.msg) {
          setAlertModal({
            show: true,
            msg: res?.msg,
            list: [],
          });
          setIsResetOtp(true);
        } else {
          if (res?.id) {
            let currentDate = new Date();
            let currentTime = moment(
              currentDate.setMinutes(currentDate.getMinutes() - 20)
            ).format("HH:mm");
            localStorage.setItem("sessiontime", res?.sessionTime);
            apiPostCreateJoinSession(
              userProfile,
              res?.id,
              "5",
              currentTime,
              "true",
              localStorage.getItem("token")!
            ).then((response) => {
              if (
                response ==
                `SyntaxError: Unexpected token 'I', "Invalid Token" is not valid JSON`
              ) {
                localStorage.clear()
                navigate("/login")
              }
              else {
                if (response?.session?.msg == "you missed the session") {
                  setAlertModal({
                    show: true,
                    msg: response?.session?.msg,
                    list: [],
                  });

                  setIsJoinRoomLoader(false);
                } else {
                  localStorage.setItem(
                    "remainingSessionTime",
                    response?.session?.RemainingTime
                  );
                  localStorage.setItem("sessionCode", response.session?.session_code);
                  localStorage.setItem("identity", response?.session?.identity)
                  localStorage.setItem("createGroup", "true");
                  localStorage.setItem("id", res.id)
                  localStorage.setItem("RemainingTime", response?.session?.RemainingTime);
                  navigate("/enteringRoom", {
                    state: {
                      itemId: response.session.session_code,
                      createGroupRoom: "true",
                      identity: response?.session?.identity,
                      roomId: res?.id,
                      remainingTime: response?.session?.RemainingTime,
                    },
                  });
                }
                setIsJoinRoomLoader(false);
              }
            });
          } else {
            setAlertModal({
              show: true,
              msg: t("YouHaveEnteredWrongInviteCode"),
              list: [],
            });
            setIsResetOtp(true);
          }
        }
        setIsJoinRoomLoader(false);
      }
    );
  };

  const InputValue = (item: string) => {
    setOtp(item);
  };

  useEffect(() => {

    const interval = setInterval(
      async () => setCurrentTime(moment(await getDate()).format("YYYY-MM-DD HH:mm:ss")),
      30000
    );
    return () => clearInterval(interval)
  }, [currentTime]);

  return (
    <>
      <div className="bgImage">
        <Navbar />
        <div className="BookingCards_Outer container justify-content-center">
          <div className="card roomBookingCard MyProfileCard">
            <p className="cardHeading alignSelf">{t('roomAvailableForBookings')}</p>
            <DatePickerComponent
              OnChange={onChange}
              value={isDate}
              onSelect={(date: any) => {
                setDate(date);
              }}
            />

            {isRoomStarted ? (
              <h5 className="expiredTime_msg">
                {t("expiredRoomToday")}
                {t("bookRoomTomorrow")}
              </h5>
            ) : (
              <>
                <p className="subjectTxt">{t("mathematics")}</p>
                {isLoading ? (
                  <div className="preload-content">
                    <Loader type="bubble-top" bgColor={"#005190"} size={80} />
                  </div>
                ) : roomData?.length > 0 ? (
                  roomData.map((item: any) => {
                    return (
                      <>
                        <ClassJoiner
                          BookClassRoom={true}
                          remainTime={getRemainingTime(
                            item.sessiontime,
                            currentTime
                          )}
                          subject={item.subject}
                          bookingTime={
                            item.bookingstatus === 0
                              ? 0
                              : getTimeFormat(item.sessiontime)
                          }
                          sessiontime={formatTime(item.sessiontime)}
                          startdate={item.startdate}
                          isLoading={selectedId === item.id}
                          min={moment(isDate).isAfter(new Date())? 0 : fiveMin}
                          Onclick={() => bookedStatus(item.id)}
                          Onpress={() => cancelStatus(item.id)}
                          Onclickjoin={() => joinRoom(item)}
                        />
                      </>
                    );
                  })
                ) : (
                  <h3>{t("noRoomFoundAfterSomeTime")}</h3>
                )}
                <p
                  className="HelpText"
                  onClick={() =>
                    setAlertModal({
                      show: true,
                      msg: t('toMakeBookings'),
                      list: FOR_BOOKING_POINTS,
                      className: "modal_inner",
                    })
                  }
                >
                  {t("doYouNeedHelp")}
                </p>
              </>
            )}
          </div>
          <div className="card roomBookingCard MyProfileCard joinFriendRoom">
            <p className="cardHeading alignSelf">{t("joinFriendRoom")}</p>
            <img
              src={LockSign}
              alt=""
              width="362px"
              height="207px"
              className="LockSignIcon"
            />
            <OtpInputComponent
              InputData={InputValue}
              isResetOtp={isResetOtp}
              onResetInput={() => setIsResetOtp(false)}
            />
            {otp.length == 4 ? (
              <div id="buttonContainer">
                {isJoinRoomLoader && (
                  <div className="preload-content">
                    <Loader type="bubble-top" bgColor="#005190" size={40} />
                  </div>
                )}
                <ButtonComponent
                  BtnText={t("JoinGroupRoom")}
                  Onclick={() => joinGroupRoom()}
                />
              </div>
            ) : (
              <ButtonComponent BtnText={t("joinGroupRoom")} otpButton={true} />
            )}
            <p
              className="HelpText"
              onClick={() =>
                setAlertModal({
                  show: true,
                  msg: t('forFindCodeTitle'),
                  list: FOR_FIND_CODE,
                  className: "modal_inner",
                })
              }
            >
              {t("whereDoFindTheCode")}
            </p>
          </div>
        </div>
        <Footer />
      </div>
      {alertModal.show && (
        <CommonModal
          title={alertModal.msg}
          onClose={() => setAlertModal({ show: false, msg: "" })}
          list={alertModal.list}
          className={alertModal.className}
          buttonText={OK}
        />
      )}
    </>
  );
};
export default BookGrouproomScreen;
