import { useEffect, useRef } from "react";
type Props = {
  track: any
}
export default function VideoTrack({ track }: Props) {
  const ref: any = useRef();

  useEffect(() => {
    if (track) {
      const el = ref.current;
      track.attach(el);

      return () => {
        track.detach(el);
      };
    }
  }, [track]);

  return <video style={{ maxWidth: "100%", width: "100%" }} ref={ref} />;
}
