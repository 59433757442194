import instagramIcon from '../assets/images/instagram.png'
import linkedinIcon from '../assets/images/linkedin.png'
import tiktokIcon from '../assets/images/tiktok.png'

export const BASE_URL = 'https://52.207.89.218/'
// export const BASE_URL = 'http://localhost:4000/'

export const POST_REQUEST = 'POST'
export const DELETE_REQUEST = 'DELETE'
export const PATCH_REQUEST = 'PATCH'
export const CONTENT_TYPE = 'application/json'
export const STARTINDEX = 1
export const ENDINDEX = 20
export const Access_Token = 'x-access-token'
export const PUBLIC_TOKEN = 'Logicease123'
export const GET_REQUEST = 'GET'
export const HEADER = {
  'Content-Type': CONTENT_TYPE,
}
export const STAY_SAFE = 'Stay safe online'
export const DESCRIPTION_ONE =
  "At Edgar, the security and protection of our users is of utmost importance to us. That's why we have a dedicated security team that works tirelessly to ensure a safe and secure environment for all of our users."
export const DESCRIPTION_TWO =
  "Our security team thoroughly reviews each chat room and closely monitors activities to ensure that no unwanted behavior or content is present on our platform. We have also implemented a variety of security measures to safeguard users' personal information and prevent unauthorized access to their accounts."
export const DESCRIPTION_THREE =
  'Our top priority is to provide users with a safe and secure platform where they can interact and communicate with each other without fear of their personal information or privacy being compromised. We take great pride in our security team and their efforts to maintain a high standard of security for all of our users.'
export const SUBSCRIBE = 'Subscribe'
export const SUBMIT = 'Submit'
export const FOLLOW_US = 'Follow us'
export const CONTACT_US = 'Contact us'
export const EMAIL = 'contact@edgar.education'
export const TO_MAKE_A_BOOKING = 'To make a booking for a study partner:'
export const FOR_FIND_CODE_TITLE =
  'To find the 4-digit code and join a study session with friends:'
export const OK = 'OK'
export const DISCONNECT_ROOM = 'disconnect'
export const FOR_BOOKING_POINTS = [
  {
    text: 'ChooseTimeToStudy',
  },
  {
    text: 'BookSession',
  },
  {
    text: 'JoinSessionWhenThereIsSecondsLeft',
  },
  {
    text: 'StudyWithYourPartnerInVirtualRoom',
  },
]
export const FOR_FIND_CODE = [
  {
    text: 'HaveFriendCreateStudyRoom',
  },
  {
    text: 'CreatorWillSeeFourDigitCode',
  },
  {
    text: 'AskYourFriendForCode',
  },
  {
    text: 'UseCodeToJoinStudySession',
  },
]
export const navigation = [
  {
    name: 'home',
    route: '/bookGroupRoom',
  },
  {
    name: 'myBookings',
    route: '/myBookings',
  },
  {
    name: 'createGroupRoom',
    route: '/createGroupRoom',
  },
  {
    name: 'security',
    route: '/security',
  },
]
export const socialIcons = [
  {
    image: tiktokIcon,
    route: ' https://www.tiktok.com/@edgareducation',
    class: 'socialIcon',
  },
  {
    image: instagramIcon,
    route: 'https://www.instagram.com/edgar.education/',
  },
  {
    image: linkedinIcon,
    route: 'https://www.linkedin.com/company/edgareducation/?viewAsMember=true',
  },
]
