import { useCallback, useEffect } from "react";
import {
  apiCreateRoom,
  apiPostCreateJoinSession,
} from "../service/ApiServices";
import { useNavigate } from "react-router-dom";
import {
  saveCreateRoom,
  getUserProfile,
  saveJoinSession,
} from "../storage/storage";
//@ts-ignore
import Loader from "react-js-loader";
import moment from "moment";
import { t } from "i18next";
export const CreateGroupRoom = () => {
  const navigate = useNavigate();
  let oneTimeCreate = false;

  const createRoom = useCallback(() => {
    let currentDate = moment(new Date()).format("YYYY-MM-DD");
    let currentTime = moment(new Date()).format("HH:mm:ss");
    const profile = getUserProfile();
    if (profile == null) {
      navigate("/myProfile");
      alert((t("CompleteYourProfileIst")));
    }
    else if (!profile.grade && !profile.language && !profile.gender && !profile.userId) {
      navigate("/myProfile");
      alert(t("CompleteYourProfileIst"));
    }
    else { }
    apiCreateRoom(
      profile?.language,
      currentDate,
      profile?.gradeId,
      profile?.grade,
      "mathematics",
      currentTime,
      localStorage.getItem("token")!
    ).then((response) => {
      saveCreateRoom(response, profile?.token);
      let currentTime = moment(new Date()).format("HH:mm");
      apiPostCreateJoinSession(
        profile,
        response?.id,
        "5",
        currentTime,
        "true",
        localStorage.getItem("token")!
      ).then((res) => {
        if (
          response ==
          `SyntaxError: Unexpected token 'I', "Invalid Token" is not valid JSON`
        )
        {
          localStorage.clear()
          navigate("/login")
        }
        else {
          saveJoinSession(res);
          localStorage.setItem(
            "remainingSessionTime",
            res?.session?.RemainingTime
          );
          navigate("/enteringRoom", {
            state: {
              itemId: localStorage.setItem("sessionCode", res.session.session_code),
              createGroupRoom: localStorage.setItem("createGroup", "true"),
              identity: localStorage.setItem("identity", res?.session?.identity),
              roomId: localStorage.setItem("id", response?.id),
              bookRoom: localStorage.setItem("bookRoom", false.toString()),
              remainingTime: localStorage.setItem("RemainingTime", res?.session?.RemainingTime),
              ttlTime: localStorage.setItem("ttlTime", res?.session?.ttlTime),
              isFromNavBar: true
            },
          });
        }
      });
    });
  }, []);

  useEffect(() => {
    if (oneTimeCreate === false) {
      createRoom();
    }
    oneTimeCreate = true;
  }, []);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        width: "100%",
      }}
    >
      <Loader type="spinner-circle" bgColor={"#005190"} size={50} />
    </div>
  );
};

export default CreateGroupRoom;
