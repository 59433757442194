import { useEffect, useState } from "react";
import OtpInput from "react-otp-input";

type Props = {
  InputData: (item: string) => void;
  isResetOtp: boolean
  onResetInput?: () => void
};

export default function OtpInputComponent({ InputData, isResetOtp, onResetInput }: Props) {
  const handleChange = (otp: any) => {
    setState({ otp });
    InputData(otp);
  };
  const [state, setState] = useState({ otp: "" });
  useEffect(() => {
    if (isResetOtp == true) {
      setState({ otp: "" })
      InputData("")
      onResetInput?.()
    }
  }, [isResetOtp])


  return (
    <>
      <OtpInput
        value={state.otp}
        onChange={handleChange}
        numInputs={4}
        isInputNum={true}
        containerStyle={{
          alignSelf: "center",
        }}
        className="codeInput"
        inputStyle={{
          width: "68px",
          height: "68px",
          background: "#FAFAFF",
          border: "2px solid #D6D6D6",
          borderRadius: "12px",
          margin: "5px",
          fontSize: "22px",
          fontFamily: "Baloo Bhai",
          fontStyle: "normal",
          fontWeight: "400",
        }}
      />
    </>
  );
}
