import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import DatePickerIcon from "../assets/images/datePickerIcon.png";

const DatePickerComponent = (props: any) => {
  return (
    <>
      <div className="DatePickerOuter">
        <DatePicker
          placeholderText="Select Date"
          onSelect={props.onSelect}
          onChange={props.OnChange}
          selected={props.value}
          className="DatePicker"
          minDate={moment().toDate()}
          dateFormat={"dd/MM/yyyy"}
        />
        <div className="DatePickerImg-outer">
          <img src={DatePickerIcon} alt="" className="datePickerIcon" />
        </div>
      </div>
    </>
  );
};
export default DatePickerComponent;
