import JoinArrow from '../assets/images/joinArrow.png'
import moment from 'moment'
//@ts-ignore
import Loader from 'react-js-loader'
import { t } from 'i18next';

const ClassJoiner = (props: any) => {
  const getDate = () => {
    if(moment(props.startdate).format('DD/MM/YYYY') === moment(new Date()).format('DD/MM/YYYY')) 
    return t("Today")
    else if (moment().startOf('day').diff(moment(props.startdate).startOf('day'), 'days') === 1)
    return t("Yesterday")
    else if (moment().startOf('day').diff(moment(props.startdate).startOf('day'), 'days') === -1)
    return t("Tomorrow")
    else 
    return moment(props.startdate).format('DD/MM/YYYY')
  }

  return (
    <>
      { (props.bookingStatus === '4' || props.bookingStatus === '5') && props.remainTime?.expireTime < 0 ? null : props.bookingStatus !== '4' && props.bookingStatus !== '5' && props.remainTime?.expireTime <= 0 ?null : 
      <div className="classroom_outer">
        <div className={props.AllBookings ? 'MyBookings' : 'ClassJoinSection classSection'}>
          <div style={{ display: 'flex', alignItems: 'center' }} className='classSectionInner'>
            {props.AllBookings ? (
              <div>
                <div className="TimingsOuter">
                  
                  <p className="bookingTime">
                    {getDate()}
                  </p>
                  <p className="Timing">{props.sessiontime}</p>
                </div>
                <p className="RemainingTime">{props.remainTime?.showingTime}</p>
              </div>
            ) : (
              <p className="Timing">
                { props?.isTodayBookings === undefined && getDate()}{' '}
                {props.sessiontime}
                <br />
                {props.remainTime?.showingTime}
              </p>
            )}

            <p className="Timing subTxt">{props.subject}</p>
          </div>
          {props.BookClassRoom ? (
            <>
              {props.bookingTime === 0 ? (
                <>
                  {props.isLoading ? (
                    <div className="preload-content2">
                      <Loader
                        type="spinner-circle"
                        bgColor={'#005190'}
                        size={50}
                      />
                    </div>
                  ) : null}
                  <button className="bookButton" onClick={props.Onclick}>
                    {t("book")}
                  </button>
                </>
              ) : props.bookingTime > 0 && props.bookingTime < props.min ? (
                <>
                  <div style={{ display: 'flex', height: "100%" }} className='classJoinerContainer'>
                    {props.isLoading ? (
                      <div className="preload-content2">
                        <Loader
                          type="spinner-circle"
                          bgColor={'#005190'}
                          size={40}
                        />
                      </div>
                    ) : null}
                    <button
                      className="cancelButton cancel"
                      onClick={props.Onpress}
                    >
                      {t("cancel")}
                    </button>
                    <button className="bookButton bookButtonText" onClick={props.Onclickjoin}>
                      {t("join")}
                      <img src={JoinArrow} alt="" className="Arrows" />
                    </button>
                  </div>
                </>
              ) : (
                <>
                  {props.isLoading ? (
                    <div className="preload-content2">
                      <Loader
                        type="spinner-circle"
                        bgColor={'#005190'}
                        size={50}
                      />
                    </div>
                  ) : null}
                  <button
                    className="cancelButton cancel"
                    onClick={props.Onpress}
                  >
                    {t("cancel")}
                  </button>
                </>
              )}
            </>
          ) : (
            <>
              { props.bookingTime > 0 && props.bookingTime < props.min &&
                (props.bookingStatus === '1' || props.bookingStatus === '4' || props.bookingStatus === '5') &&
                props.showtime === 'true' ? (
                <>
                  <div style={{ display: 'flex' }}>
                    <>
                      {props.isLoading ? (
                        <div className="preload-content2">
                          <Loader
                            type="spinner-circle"
                            bgColor={'#005190'}
                            size={50}
                          />
                        </div>
                      ) : null}
                      <button
                        className="cancelButton cancel"
                        onClick={props.Onpress}
                      >
                        {t("cancel")}
                      </button>
                    </>
                    <button className="bookButton" onClick={props.Onclick}>
                      {props.bookingStatus === '5' || props.bookingStatus === '4' ? t('rejoin'):t("join")}
                      <img src={JoinArrow} alt='' className="Arrows" />
                    </button>
                  </div>
                </>
              ) : (
                <>
                  {props.isLoading ? (
                    <div className="preload-content2">
                      <Loader
                        type="spinner-circle"
                        bgColor={'#005190'}
                        size={50}
                      />
                    </div>
                  ) : null}

                  {props.bookingStatus === '1' && props.showtime === 'true' ? (
                    <button
                      className="cancelButton cancel"
                      onClick={props.Onpress}
                    >
                      {t("cancel")}
                    </button>
                  ) : props.bookingStatus === '1' &&
                    props.showtime === 'false' ? (
                    <p className="cancelButton booked">{t("booked")}</p>
                  ) : props.bookingStatus === '2' ? (
                    <p className="cancelButton completed">{t("completed")}</p>
                  ) : props.bookingStatus === '3' ? (
                    <p className="cancelButton">{t("Cancelled")}</p>
                  ) : props.bookingStatus === '4' ? (
                    <p className="cancelButton inRoom">{t("inRoom")}</p>
                  ) : props.bookingStatus === '5' ? (
                    <p className="cancelButton inProgress">{t("inProgress")}</p>
                  ) : props.bookingStatus === '1' &&
                    props.showtime === 'true' ? (
                    <button
                      className="cancelButton cancel"
                      onClick={props.Onpress}
                    >
                      {t("cancel")}
                    </button>
                  ) : (
                    <p className="cancelButton booked">{t("booked")}</p>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>}
    </>
  )
}
export default ClassJoiner
