import { useEffect } from 'react'
import { LoginSocialGoogle } from 'reactjs-social-login'
import logo from '../assets/images/logo.png'
import { GoogleLoginButton } from 'react-social-login-buttons'
import { apiSignIn } from '../service/ApiServices'
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom'
import {
  saveUserDetails,
  saveUserProfile,
  saveUserLogin,
  getUserProfile,
} from '../storage/storage'

const GoogleLogin = () => {
  const navigate = useNavigate()

  useEffect(() => {
    const response = getUserProfile();
    if (response) {
      if (response.userId) navigate("/bookGroupRoom");
    }
  }, []);

  const userLogin = (res: any) => {
    saveUserLogin(res)
    apiSignIn(res)
      .then((response) => {
        let userData = {
          firstname: response.firstname,
          lastname: response.lastname,
          emailId: response.emailId,
          googleId: response.googleId,
          userId: response.id,
          picture: response.picture,
          token: response.token,
        }
        localStorage.setItem('token', response?.token)
        saveUserDetails(userData)
        if (response?.existing == 'yes') {
          let userProfile = {
            id: response.user.profileId,
            emailId: response.emailId,
            grade: response.user.grade,
            language: response.user.language,
            gender: response.user.gender,
            subject: response.user.subject,
            languagecode: response.user.languagecode,
            gradeId: response.user.gradeId,
            userId: response.user.userId,
            token: response.token,
          }
          saveUserProfile(userProfile)
          if (response.user.gradeId) navigate('/bookGroupRoom')
          else navigate('/myProfile')
        } else navigate('/myProfile')
      })
      .catch((error) => {
        alert(error)
      })
  }


  return (
    <>
      <div className="bgImage">
        <div className="pageContent">
          <img src={logo} className="logo" alt='' />
          <div>
            <h3 className="heading">{t("loginWithGoogle")}</h3>
            <LoginSocialGoogle
              isOnlyGetToken
              scope="https://www.googleapis.com/auth/userinfo.email"
              client_id="717137887811-4hd7dt971v885mkovoc3h00e62lmfhst.apps.googleusercontent.com"
              onResolve={async ({ provider, data }: any) => {
                if (data.access_token) {
                  let userInfoResponse = await fetch(
                    'https://www.googleapis.com/userinfo/v2/me',
                    {
                      headers: { Authorization: `Bearer ${data.access_token}` },
                    },
                  )
                  userInfoResponse.json().then((res) => {
                    userLogin(res)
                  })
                }
              }}
              onReject={(err: any) => {
                alert(err)
              }}
              typeResponse="accessToken"
            >
              <GoogleLoginButton className="GoogleLogin_btn" text={t("loginWithGoogle") ?? ''} />
            </LoginSocialGoogle>
          </div>
        </div>
      </div>
    </>
  )
}
export default GoogleLogin


