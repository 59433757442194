import {
  SIGN_IN,
  GET_ALL_ROOM_BY_GRADE_ID,
  GET_ALL_BOOKING_BY_USER,
  GET_CURRENT_BOOKING_BY_USER,
  POST_USER_PROFILE,
  UPDATE_USER_BOOKING_STATUS,
  GET_INVITE_CODE,
  GET_TWILIO_ACCOUNT,
  Create_Room,
  CREATE_LOGIN_SESSION,
  POST_JOINED_SESSION,
  GET_USER_PROFILE,
  UPDATE_USER_PROFILE,
  LOGIN_API
} from "../constants/ApiConstants";
import { patchRequest, postRequest } from "./ApiHelper";
import { getUserDetails } from "../storage/storage";
export const apiSignIn = async (res: any) => {
  const body = {
    firstname: res.given_name,
    lastname: res.family_name,
    emailId: res.email,
    googleId: res.id,
    picture: res.picture,
  };
  const response = await postRequest(body, SIGN_IN);
  return response;
};

export const apigetAllBookingsByUser = async (
  res: any,
  currentDate: any,
  currentTime: any,
  STARTINDEX: any,
  ENDINDEX: any,
  token: string
) => {
  const body = {
    userId: res.userId,
    currentDate: currentDate,
    currentTime: currentTime,
    startIndex: STARTINDEX,
    endIndex: ENDINDEX,
  };
  const response = await postRequest(body, GET_ALL_BOOKING_BY_USER, token);
  return response;
};

export const apigetAllRoomByGradeId = async (
  res: any,
  startdate: string,
  startTime: string,
  token?: string
) => {

  const body = {
    language: res.language,
    userId: res.userId,
    startdate: startdate,
    startTime: startTime,
  };
  const response = await postRequest(body, GET_ALL_ROOM_BY_GRADE_ID, token);
  return response;
};
export const apigetCurrentBookingByUser = async (
  res: any,
  currentDate: any,
  currentTime: any,
  token: any
) => {
  const body = {
    userId: res.userId,
    currentDate: currentDate,
    currentTime: currentTime,
  };
  const response = await postRequest(body, GET_CURRENT_BOOKING_BY_USER, token);
  return response;
};
export const apipatchUserBookingStatus = async (
  userProfile: any,
  item: any,
  bookingstatus: string,
  token: any
) => {
  const body = {
    userId: userProfile.userId,
    roomId: item,
    bookingstatus: bookingstatus,
  };
  const response = await patchRequest(body, UPDATE_USER_BOOKING_STATUS, token);
  return response;
};

export const apiPostCreateJoinSession = async (
  userProfile: any,
  item: any,
  bookingstatus: string,
  currentTime: string,
  groupRoom: string,
  token: any
) => {
  const body = {
    userId: userProfile?.userId,
    roomId: item,
    bookingstatus: bookingstatus,
    members: 0,
    currentTime: currentTime,
    groupRoom: groupRoom,
  };
  const response = await postRequest(body, CREATE_LOGIN_SESSION, token);
  return response;
};
export const apipostUserProfile = async (
  grade: string,
  language: string,
  gender: string,
  languagecode: string,
  gradeId: string,
  userId: string,
  token: any
) => {
  const body = { grade, language, gender, languagecode, gradeId, userId };
  const response = await postRequest(body, POST_USER_PROFILE, token);
  return response;
};
export const apipatchUpdateProfile = async (
  grade: string,
  language: string,
  gender: string,
  languagecode: string,
  gradeId: string,
  userId: string,
  id: string,
  token: any
) => {
  let userDetail = await getUserDetails();
  const body = {
    grade,
    language,
    gender,
    languagecode,
    gradeId,
    userId,
    id,
    token,
  };
  const response = await patchRequest(
    body,
    UPDATE_USER_PROFILE,
    userDetail?.token
  );
  return response;
};
export const apigetInviteCode = async (
  invitecode: string,
  userProfile: any,
  token: any
) => {
  const body = { invitecode, userId: userProfile?.userId };
  const response = await postRequest(body, GET_INVITE_CODE, token);
  return response;
};

export const apigetTwilioToken = async (
  userDetails: any,
  identity: any,
  roomId: string
) => {
  const body = {
    userId: identity,
    roomId: roomId,
  };
  const response = await postRequest(
    body,
    GET_TWILIO_ACCOUNT,
    userDetails?.token
  );
  return response;
};
export const apiCreateRoom = async (
  language: string,
  currentDate: string,
  gradeId: string,
  grade: string,
  subject: string,
  currentTime: string,
  token: any
) => {
  const body = {
    language: language,
    startdate: currentDate,
    gradeId: gradeId,
    grade: grade,
    subject: subject,
    sessiontime: currentTime,
    creategrouproom: "1",
  };
  const response = await postRequest(body, Create_Room, token);
  return response;
};
export const apiPostGetJoinSession = async (
  userProfile: any,
  roomId: any,
  token: any
) => {
  const body = {
    userId: userProfile?.userId,
    roomId: roomId,
  };
  const response = await postRequest(body, POST_JOINED_SESSION, token);
  return response;
};
export const apiGetUserPicture = async (Identity: any, token: any) => {
  const body = {
    googleId: Identity,
  };
  const response = await postRequest(body, GET_USER_PROFILE, token);
  return response;
};
export const apiLogin = async (username: string, password: string) => {
  const body = {
    username: username,
    password: password,
  };
  const response = await postRequest(body, LOGIN_API);
  return response;
};