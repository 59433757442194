import AudioTrack from "./AudioTrack";
import VideoTrack from "./VideoTrack";
import useTrack from "../useTrack";
import userIcon from "../assets/images/userIcon.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrophoneSlash } from "@fortawesome/free-solid-svg-icons";
type Props = {
  participant: any;
  userDetails: any;
}


function Participant({ participant }: Props) {
  const { videoOn, audioOn, videoTrack, audioTrack } = useTrack({
    participant,
  });
  return (
    <div className={`videoMain ${audioOn ? "activeVideo" : ""}`}>
      {videoOn ? (
        <VideoTrack track={videoTrack} />
      ) : (
        <div className="videoNotAvailable">
          <img src={userIcon} alt="User Icon" />
        </div>
      )}
      <br />
      {audioOn ? (
        <AudioTrack track={audioTrack} />
      ) : (
        <FontAwesomeIcon
          className="muteIcon"
          color={videoOn ? "" : "#fff"}
          icon={faMicrophoneSlash}
        />
      )}
    </div>
  );
}

export default Participant;
