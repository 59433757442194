import Navbar from "../components/Navbar";
import { Link } from "react-router-dom";
import CoworkersIcon from "../assets/images/coworkersIcon.png";
import FocusAbstract from "../assets/images/focusAbstract.png";
import { t } from "i18next";

export const BookNextRoomScreen = () => {
  return (
    <div className="bgImage">
      <Navbar />
      <div className="outer_Container">
        <center><div
          className="card MyProfileCard msgCard"
          style={{ width: "605px", height: "426px" }}
        >
          <p className="cardHeading alignSelf">{t("WowYouAreUnique")}</p>
          <p className="questionsTxt Msg">
            {t("NoMatching")}
          </p>
          <Link to="/bookGroupRoom" className="BookRoom_txt">
            <p>{t("BookTheNextRoomHere")}</p>
          </Link>
        </div>
        <img className="CoworkersIcon" src={CoworkersIcon} alt="" />
        <img className="FocusAbstract" src={FocusAbstract} alt="" /></center>
      </div>
    </div>
  );
};
export default BookNextRoomScreen;
