import { memo, useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import { useNavigate } from 'react-router-dom'
import ClassJoiner from '../components/ClassJoiner'
import Calender from '../assets/images/calender.png'
import {
  apiPostCreateJoinSession,
  apigetAllBookingsByUser,
  apigetCurrentBookingByUser,
  apipatchUserBookingStatus,
} from '../service/ApiServices'
//@ts-ignore
import Loader from 'react-js-loader'
import { t } from 'i18next';
import { getUserProfile, saveMyBookings } from '../storage/storage'
import Footer from '../components/Footer'
import { STARTINDEX, ENDINDEX, OK } from '../constants/Constants'
import moment from 'moment'
import CommonModal from '../components/CommonModal'
import getDate from '../useDate'
export const MyBookingsScreen = memo(() => {
  const [roomsData, setClassroomsData] = useState<any>([])
  const [apiEnd, setApiEnd] = useState<any>(false)
  const [currentData, setCurrentdata] = useState<any>([])
  const [isLoading, setIsLoading] = useState(true)
  const [lastIndex, setLastIndex] = useState<any>(ENDINDEX)
  const [currentTime, setCurrentTime] = useState<any>(
    moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
  );
  // const currentTime = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')

  const [userProfile, setUserProfile] = useState<string>()
  const [classRoomData, setClassRoomData] = useState<any>([])
  const [selectedId, setSelectedId] = useState<any>(-1)
  const [loading, setLoading] = useState(true)
  const [alertModal, setAlertModal] = useState<any>({ show: false, msg: '' })
  const [isLoadMoreLoader, setIsLoadMoreLoader] = useState(false)
  const [isLoadMore, setIsLoadMore] = useState(false)
  const [currentDate, setCurrentDate] = useState<any>(new Date());

  const navigate = useNavigate()
  function formatTime(timeString: any) {
    const [hourString, minute] = timeString.split(':')
    const hour = +hourString % 24
    return (hour % 12 || 12) + ':' + minute + (hour < 12 ? ' a.m.' : ' p.m.')
  }
  let oneTimeGetBookings = false;

  const getBookings = async () => {
    const res = getUserProfile()
    if (res) setUserProfile(res);
    if (res == null) {
      setUserProfile(res);
      navigate("/myProfile");
      alert(t("CompleteYourProfileIst"));
    }
    else if (!res.grade && !res.language && !res.gender && !res.userId) {
      navigate("/myProfile");
      alert(t("CompleteYourProfileIst"));
    }
    else { }
    setLoading(true)
    setIsLoading(true)
    const date = await getDate()
    setCurrentDate(date)
    let currentDate = moment(date).format('YYYY-MM-DD')
    let currentTime = moment(date).format('HH:mm:ss')
    apigetAllBookingsByUser(
      res,
      currentDate,
      currentTime,
      STARTINDEX,
      ENDINDEX,
      localStorage.getItem('token')!,
    ).then((response) => {
      if (
        response ==
        `SyntaxError: Unexpected token 'I', "Invalid Token" is not valid JSON`
      )
      {
        localStorage.clear()
        navigate("/login")
      }
      else {
        setCurrentTime(moment(date).format("YYYY-MM-DD HH:mm:ss"))
        setClassroomsData(response[0])
        setClassRoomData(response[1][0])
        setApiEnd(true)
        saveMyBookings(response, res?.token)
        setIsLoadMore(true)
      }
    })

    apigetCurrentBookingByUser(
      res,
      currentDate,
      currentTime,
      localStorage.getItem('token')!,
    ).then((response) => {
      if (
        response ==
        `SyntaxError: Unexpected token 'I', "Invalid Token" is not valid JSON`
      )
      {
        localStorage.clear()
        navigate("/login")
      }
      else {
        setCurrentdata(response)
        setApiEnd(true)
      }
    })
  }
  const getAllBookingOnPagination = () => {
    setIsLoadMoreLoader(true)
    const res = getUserProfile()
    if (res) {
      setUserProfile(res)
    }
    let currentDate = moment(new Date()).format('YYYY-MM-DD')
    let currentTime = moment(new Date()).format('HH:mm:ss')
    apigetAllBookingsByUser(
      res,
      currentDate,
      currentTime,
      lastIndex + STARTINDEX,
      lastIndex + ENDINDEX,
      localStorage.getItem('token')!,
    )
      .then((response) => {
        if (
          response ==
          `SyntaxError: Unexpected token 'I', "Invalid Token" is not valid JSON`
        )
        {
          localStorage.clear()
          navigate("/login")
        }
        else {
          setClassRoomData(response)
          setApiEnd(true)
          saveMyBookings(response, res?.token)
          setClassroomsData([...roomsData, ...response[0]])
          setLastIndex(lastIndex + ENDINDEX)
          setIsLoadMoreLoader(false)
          if (response.length === 0) {
            setIsLoadMore(false)
          }
        }
      })
      .catch((error) => {
        if (error) {
          setIsLoadMoreLoader(false)
        }
      })
  }
  useEffect(() => {
    if (oneTimeGetBookings === false) {
      getBookings()
    }
    oneTimeGetBookings = true;
  }, [])

  useEffect(() => {
    if(apiEnd) {
        setIsLoading(false)
        setLoading(false)
      
    }
  },[apiEnd])

  const getTimeFormat = (sessiontime: any) => {
    let SelectedDate = moment(currentDate).format('YYYY-MM-DD')
    let currenttime = moment(currentDate).format('YYYY-MM-DD HH:mm:ss')
    let sessionTime = moment(SelectedDate + sessiontime, 'YYYY-MM-DD HH:mm:ss')
    var duration: any = moment.duration(sessionTime.diff(currenttime))
  // const fiveMinutes = moment.duration(5, "minutes");

  // if (duration.asMilliseconds() <= -fiveMinutes.asMilliseconds()) {
  //   // Current time exceeds session time by 5 minutes or more
  //   return "Session time has already passed.";
  // }
    return duration
  }

  const fiveMin = 5 * 60 * 1000
  const getRemainingTime = (sessiontime: any, currenttime: any): { expireTime: number, showingTime: string } => {
    let SelectedDate = moment(new Date()).format('YYYY-MM-DD')
    let sessionTime = moment(SelectedDate + sessiontime, 'YYYY-MM-DD HH:mm:ss')
    var duration: any = moment.duration(sessionTime.diff(currenttime))

    var hours = parseInt(duration.asHours())
    var minutes = parseInt(duration.asMinutes()) % 60
    var time =
       hours > 0 && hours < 24  ? hours + t('HourAnd') + minutes + " min" : hours > 23 ? (hours/24).toFixed(0) + t('DaysLeft')  :  minutes + "min";
    localStorage.setItem('time', time)

    return { expireTime: minutes, showingTime: time }
  }
  const joinRoom = async (item: any) => {
    let currentTime = moment(await getDate()).format('HH:mm')
    apiPostCreateJoinSession(
      userProfile,
      item.id,
      '5',
      currentTime,
      'false',
      localStorage.getItem('token')!,
    ).then((response) => {
      if (
        response ==
        `SyntaxError: Unexpected token 'I', "Invalid Token" is not valid JSON`
      )
      {
        localStorage.clear()
        navigate("/login")
      }
      else {
        if (response?.session?.msg === 'you missed the session') {
          setAlertModal({ show: true, msg: response?.session?.msg })
          getBookings()
        } else {
          localStorage.setItem(
            'remainingSessionTime',
            response?.session?.RemainingTime,
          )
          localStorage.setItem(
            'id',
            item.id,
          )
          navigate('/enteringRoom', {
            state: {
              itemId: response.session.session_code,
              createGroupRoom: 'false',
              identity: response?.session?.identity,
              roomId: item.id,
              remainingTime: response?.session?.RemainingTime,
              sessionTime: item?.sessiontime ?? '',
            },
          })
        }
      }
    })
  }
  const cancelStatus = (item: any) => {
    setSelectedId(item)
    apipatchUserBookingStatus(
      userProfile,
      item,
      '0',
      localStorage.getItem('token')!,
    ).then((response) => {
      if (
        response ==
        `SyntaxError: Unexpected token 'I', "Invalid Token" is not valid JSON`
      )
      {
        localStorage.clear()
        navigate("/login")
      }
      else {
        setSelectedId(-1)
        getBookings()
      }
    })
  }

  useEffect(() => {

    const interval = setInterval(
      async () => setCurrentTime(moment(await getDate()).format("YYYY-MM-DD HH:mm:ss")),
      30000
    );
    return () => clearInterval(interval)
  }, [currentTime]);

  return (
    <div className="bgImage">
      <Navbar />
      <div className="BookingCards_Outer container justify-content-between">
        <div className="card roomBookingCard MyProfileCard bookingCard myBooking joinClassroom_Card scrollBar">
          <div className="fixedHeader">
            <p className="cardHeading">{t("allBookings")}</p>
          </div>
          {isLoading ? (
            <div className="preload-content">
              <Loader type="bubble-top" bgColor={'#005190'} size={80} />
            </div>
          ) : roomsData?.length > 0 ? (
            roomsData?.map((item: any) => {
              return (
                <>
                  <ClassJoiner
                    sessiontime={formatTime(item.sessiontime)}
                    startdate={item.startdate}
                    remainTime={
                      item.showTime === 'true'
                        ? getRemainingTime(item.sessiontime, currentTime)
                        : null
                    }
                    min={fiveMin}
                    showtime={item.showTime}
                    subject={item.subject}
                    bookingStatus={item.bookingstatus}
                    isLoading={selectedId === item.id}
                    bookingTime={getTimeFormat(item.sessiontime)}
                    Onclick={() => joinRoom(item)}
                    Onpress={() => cancelStatus(item.id)}
                  />
                </>
              )
            })
          ) : (
            <h3>{t("bookingsMessage")}</h3>
          )}
          {
            classRoomData.Total_UserBooking > ENDINDEX ?
              (roomsData.length > 0 && isLoadMore ? (
                <div id="buttonContainer">
                  {isLoadMoreLoader && (
                    <div className="preload-content">
                      <Loader type="bubble-top" bgColor={'#005190'} size={40} />
                    </div>
                  )}
                  <button
                    onClick={getAllBookingOnPagination}
                    className="button mx-auto"
                  >
                    {t("loadMore")}
                  </button>
                </div>
              ) : null) : null}
        </div>
        <div className="todayBookingContainer">
          <div className="card roomBookingCard bookingCard MyProfileCard todayBooking joinClassroom_Card">
            <div className="fixedHeader">
              <p className="cardHeading">{t("todayBookings")}</p>
            </div>
            {isLoading ? (
              <div className="preload-content">
                <Loader type="bubble-top" bgColor={'#005190'} size={80} />
              </div>
            ) : null}
            {roomsData?.length > 0 ? (
              currentData?.map((item: any) => {
                return (
                  <ClassJoiner
                  isTodayBookings={true}
                    ClassroomsData={currentData}
                    sessiontime={formatTime(item.sessiontime)}
                    remainTime={
                      item.showTime === 'true'
                        ? getRemainingTime(item.sessiontime, currentTime)
                        : null
                    }
                    min={fiveMin}
                    showtime={item.showTime}
                    subject={item.subject}
                    bookingStatus={item.bookingstatus}
                    isLoading={selectedId === item.id}
                    bookingTime={getTimeFormat(item.sessiontime)}
                    Onclick={() => joinRoom(item)}
                    Onpress={() => cancelStatus(item.id)}
                  />
                )
              })
            ) : loading ? null : (
              <h3>{t("bookingsMessage")}</h3>
            )}
          </div>
          <img src={Calender} alt="Calender" />
        </div>
      </div>
      {alertModal.show && (
        <CommonModal
          title={alertModal.msg}
          onClose={() => setAlertModal({ show: false, msg: '' })}
          buttonText={OK}
        />
      )}
      <Footer />
    </div>
  )
})
export default MyBookingsScreen
