import { useState } from "react";
import { apigetTwilioToken } from "../service/ApiServices";
import { getUserDetails } from "../storage/storage";
import { useNavigate } from "react-router-dom";

const INITIAL_STATE = {
  isLoading: false,
  error: null,
  token: null,
  identity: null,
  roomName: null,
};
/**
 * get token to connect to a room
 */
export default function useConfig() {
  const navigate = useNavigate();
  const [config, setConfig] = useState<any>(INITIAL_STATE);

  const getToken = async ({ identity, roomName }: any) => {
    if (identity && roomName) {
      setConfig({ ...INITIAL_STATE, isLoading: true });
      let userDetail = await getUserDetails();
      apigetTwilioToken(userDetail, identity, roomName)
        .then((result) => {
          if (
            result ==
            `SyntaxError: Unexpected token 'I', "Invalid Token" is not valid JSON`
          )
          {
            localStorage.clear()
            navigate("/login")
          }
          else {
            if (result.token) {
              setConfig({
                isLoading: false,
                token: result.token,
                identity,
                roomName,
              });
            }
          }
        })
        .catch((error) => {
          setConfig((c: any) => ({ ...c, isLoading: false, error }));
        });
    }
  };
  return { ...config, getToken };
}
