//@ts-ignore
import Loader from "react-js-loader";

const ButtonComponent = (props: any) => {
  return (
    <>
      <button
        className={props.otpButton ? "disabled" : "button"}
        style={{ alignSelf: "center" }}
        onClick={props.Onclick}
      >
        {props.isLoading ? <Loader type="bubble-top" bgColor={"#005190"} size={24} />
          : props.BtnText}
      </button>
    </>
  );
};
export default ButtonComponent;
