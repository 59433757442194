import {
  BASE_URL,
  GET_REQUEST,
  HEADER,
  PATCH_REQUEST,
  POST_REQUEST,
  PUBLIC_TOKEN,
} from "../constants/Constants";

export const getRequest = async (additionalUrl: string, BODY: any = {}) => {
  try {
    const response = await fetch(BASE_URL + additionalUrl, {
      method: GET_REQUEST,
      headers: HEADER,
    });
    const json = await response.json();
    return json;
  } catch (err) {
    return null;
  }
};

export const postRequest = async (
  BODY: any,
  additionalUrl: string,
  token?: string
) => {
  try {
    const response = await fetch(BASE_URL + additionalUrl, {
      method: POST_REQUEST,
      headers: { ...HEADER, "x-access-token": token ?? PUBLIC_TOKEN },
      body: JSON.stringify(BODY),
    });
    const json = await response.json();
    return json;
  } catch (err) {
    return err;
  }
};

export const patchRequest = async (
  BODY: any,
  additionalUrl: string,
  token?: string
) => {
  try {
    const response = await fetch(BASE_URL + additionalUrl, {
      method: PATCH_REQUEST,
      headers: { ...HEADER, "x-access-token": token ?? PUBLIC_TOKEN },
      body: JSON.stringify(BODY),
    });
    const json = await response.json();
    return json;
  } catch (err) {
    return err;
  }
};
