import { useEffect, useState } from "react";
import ButtonComponent from "../components/Button";
import Navbar from "../components/Navbar";
import { useNavigate } from "react-router-dom";
import { getUserDetails, saveUserProfile } from "../storage/storage";
import Options from "../components/Options";
//@ts-ignore
import { apipostUserProfile } from "../service/ApiServices";
import CommonModal from "../components/CommonModal";
import Footer from "../components/Footer";
import { t } from 'i18next';
import { OK } from "../constants/Constants";

export const MyProfileScreen = () => {
  const navigate = useNavigate();
  const [gradeDataValue, setGradeDataValue] = useState<any>();
  const [languageDataValue, setLanguageDataValue] = useState<any>();
  const [valueentityDataValue, setValueEntityDataValue] = useState<any>();
  const [isLoading, setIsLoading] = useState(false)
  const [userDetails, setUserDetails] = useState<any>([]);
  const [alertModal, setAlertModal] = useState<any>({ show: false, msg: "" });

  const gradeData = [
    {
      value: 7,
      placeholder: "7th grade",
    },
    {
      value: 8,
      placeholder: "8th grade",
    },
    {
      value: 9,
      placeholder: "9th grade",
    },
    {
      value: 10,
      placeholder: "First year of high school",
    },
    {
      value: 11,
      placeholder: "Second year of high school",
    },
    {
      value: 12,
      placeholder: "Last year of high school",
    },
  ];
  const languageData = [
    {
      value: 1,
      placeholder: "Svenska",
    },
    {
      value: 2,
      placeholder: "English",
    },
    {
      value: 3,
      placeholder: "عربي",
    },
    {
      value: 4,
      placeholder: "Deutsch",
    },
    {
      value: 5,
      placeholder: "Español",
    },
    {
      value: 6,
      placeholder: "中文",
    },
    {
      value: 7,
      placeholder: "Other",
    },
  ];
  const valueentityData = [
    {
      value: 1,
      placeholder: "Female",
    },
    {
      value: 1,
      placeholder: "Male",
    },
    {
      value: 1,
      placeholder: "Non-binary",
    },
    {
      value: 1,
      placeholder: "I'dRatherNotSay",
    },
  ];

  const onSubmit = () => {
    if (
      gradeDataValue === undefined ||
      languageDataValue === undefined ||
      valueentityDataValue === undefined
    ) {
      setAlertModal({
        show: true,
        msg: "Please answer all required questions.",
      });
    } else
      setIsLoading(true)
    apipostUserProfile(
      gradeDataValue?.placeholder,
      languageDataValue?.placeholder,
      valueentityDataValue?.placeholder,
      languageDataValue?.value,
      gradeDataValue?.value,
      userDetails.userId,
      localStorage.getItem("token")!
    )
      .then((response) => {
        if (
          response ==
          `SyntaxError: Unexpected token 'I', "Invalid Token" is not valid JSON`
        )
        {
          localStorage.clear()
          navigate("/login")
        }
        else {

          let userProfile = {
            id: response.id,
            grade: response.grade,
            language: response.language,
            gender: response.gender,
            languagecode: response.languagecode,
            gradeId: response.gradeId,
            userId: response.userId,
            token: userDetails.token,
          };
          saveUserProfile(userProfile);
          navigate("/bookGroupRoom");
          setIsLoading(false)
        }
      })
      .catch((error) => {});
  };
  useEffect(() => {
    const res = getUserDetails();
    if (res) {
      setUserDetails(res);
    }
  }, []);


  return (
    <div className="bgImage">
      <Navbar />
      <div className="card MyProfileCard  mx-auto">
        <p className="cardHeading">{t("myProfileTitle")}</p>
        <Options
          question={t("imGrade")}
          data={gradeData}
          onpress={(item: any) => setGradeDataValue(item)}
          value={gradeDataValue}
        />
        <Options
          question={t("myNativeLanguage")}
          data={languageData}
          onpress={(item: any) => setLanguageDataValue(item)}
          value={languageDataValue}
        />
        <Options
          question={t("iIdentifyAs")}
          data={valueentityData}
          onpress={(item: any) => setValueEntityDataValue(item)}
          value={valueentityDataValue}
        />
        <ButtonComponent BtnText="Save" isLoading={isLoading} Onclick={() => onSubmit()} />
      </div>
      {alertModal.show && (
        <CommonModal
          title={alertModal.msg}
          onClose={() => setAlertModal({ show: false, msg: "" })}
          buttonText={OK}
        />
      )}
      <Footer />
    </div>
  );
};
export default MyProfileScreen;
