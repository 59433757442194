import { useEffect } from 'react'
import stopSign from '../assets/images/stopSign.png'
import Navbar from '../components/Navbar'
import { t } from 'i18next';
import Footer from '../components/Footer'
import { useNavigate } from 'react-router-dom'
import { getUserProfile } from '../storage/storage'

export const SecurityScreen = () => {
  const navigate = useNavigate()
  let oneTimeUserProfile = false;
  useEffect(() => {
    if (oneTimeUserProfile === false) {
      const res = getUserProfile()
      if (res == null) {
        navigate("/myProfile");
        alert(t("CompleteYourProfileIst"));
      }
      else if (!res.grade && !res.language && !res.gender && !res.userId) {
        navigate("/myProfile");
        alert(t("CompleteYourProfileIst"));
      }
      else { }
    }
    oneTimeUserProfile = true;
  }, [])


  return (
    <div className="bgImage">
      <Navbar />
      <div className="main">
        <div className="card securityCard" id="securityCard">
          <p className="cardHeading2">{t("staySafe")}</p>
          <p className="securityText">{t("description1")}</p>
          <p className="securityText">{t("description2")}</p>
          <p className="securityText">{t("description3")}</p>
        </div>
        <div className="imageContainer">
          <img src={stopSign} alt='' className="stopSignImg" />
        </div>
      </div>
      <Footer />
    </div>
  )
}
export default SecurityScreen
