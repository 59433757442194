import './App.css'
import '../src/assets/css/index.css'
import '../src/assets/css/responsive.css'
import RoutesComponent from './navigation/Routes'
import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import languageDetector from 'i18next-browser-languagedetector';
import { BrowserRouter } from 'react-router-dom'
import en from './translation/en.json'
import sv from './translation/sv.json'
import firebase from 'firebase'

i18n.use(languageDetector)
  .use(initReactI18next).init({
    resources: {
      en,
      sv
    },
    lng: navigator.language,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    }
  });

function App() {
  // Import the functions you need from the SDKs you need

  const firebaseConfig = {
    apiKey: 'AIzaSyB3CynaBDuv8PNFlJtpfNCq28UT7KYn0-Y',
    authDomain: 'edgar-378f8.firebaseapp.com',
    projectId: 'edgar-378f8',
    storageBucket: 'edgar-378f8.appspot.com',
    messagingSenderId: '1076824610599',
    appId: '1:1076824610599:web:42bf2c8789f17ad1f6bb02',
    measurementId: 'G-B7Z4S6HEGQ',
  }

  // Initialize Firebase
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig)
  } else {
    firebase.app()
  }

  return (
    <BrowserRouter>
      <div className="layout__content">
        <div className="layout__content-main">
          <RoutesComponent />
        </div>
      </div>
    </BrowserRouter>
  )
}

export default App
