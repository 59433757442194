// import { reactLocalStorage } from 'reactjs-localstorage'
const USERDATA = 'userData'
const USERPROFILE = 'userProfile'
const BOOKINGROOM = 'bookingRoom'
const ALLBooking = 'allBooking'
const CurrentBooking = 'currentBooking'
const CREATEROOM = 'createRoom'
const CREATEJOINSESSION = 'createJoinSession'
const USERLOGIN = 'userLogin'
const UPDATEUSERPROFILE = 'updateProfile'
export const saveUserDetails = async (value: any) => {
  try {
    const jsonValue = JSON.stringify(value)
    await localStorage.setItem(USERDATA, jsonValue)
  } catch (e) {
    alert(e)
  }
}
export const getUserDetails = () => {
  try {
    const value: any = localStorage.getItem(USERDATA)
    return JSON.parse(value)
  } catch (e) {
    // alert("asyncLogin");
    // error reading value
  }
}
export const saveUserProfile = async (value: any) => {
  try {
    const jsonValue = JSON.stringify(value)
    await localStorage.setItem(USERPROFILE, jsonValue)
  } catch (e) {
    alert(e)
  }
}
export const getUserProfile = () => {
  try {
    const value: any = localStorage.getItem(USERPROFILE)
    return JSON.parse(value)
  } catch (e) { }
}
export const saveBookingRoom = async (
  value: any,
  token: string | undefined,
) => {
  try {
    const data = {
      token,
      roomData: value,
    }
    const jsonValue = JSON.stringify(data)
    await localStorage.setItem(BOOKINGROOM, jsonValue)
  } catch (e) {
    alert(e)
  }
}
export const getBookingRoom = () => {
  try {
    const value: any = localStorage.getItem(BOOKINGROOM)
    return JSON.parse(value)
  } catch (e) {
    // alert("asyncLogin");
    // error reading value
  }
}
export const saveMyBookings = async (value: any, token: string | undefined) => {
  try {
    const data = {
      token,
      roomsData: value,
    }
    const jsonValue = JSON.stringify(data)
    await localStorage.setItem(ALLBooking, jsonValue)
  } catch (e) {
    alert(e)
  }
}
export const getMyBookings = () => {
  try {
    const value: any = localStorage.getItem(ALLBooking)
    return JSON.parse(value)
  } catch (e) {
    // alert("asyncLogin");
    // error reading value
  }
}
export const saveCurrentBookings = async (
  value: any,
  token: string | undefined,
) => {
  try {
    const data = {
      token,
      currentData: value,
    }
    const jsonValue = JSON.stringify(data)
    await localStorage.setItem(CurrentBooking, jsonValue)
  } catch (e) {
    alert(e)
  }
}
export const saveCreateRoom = async (value: any, token: string | undefined) => {
  try {
    const data = {
      token,
      roomData: value,
    }
    const jsonValue = JSON.stringify(data)
    await localStorage.setItem(CREATEROOM, jsonValue)
  } catch (e) {
    alert(e)
  }
}
export const saveJoinSession = async (value: any) => {
  try {
    const data = {
      roomData: value,
    }
    const jsonValue = JSON.stringify(data)
    await localStorage.setItem(CREATEJOINSESSION, jsonValue)
  } catch (e) {
    alert(e)
  }
}
export const getJoinSession = () => {
  try {
    const value: any = localStorage.getItem(CREATEJOINSESSION)
    return JSON.parse(value)
  } catch (e) {
    // alert("asyncLogin");
    // error reading value
  }
}
export const getCreateRoom = () => {
  try {
    const value: any = localStorage.getItem(CREATEROOM)
    return JSON.parse(value)
  } catch (e) {
    // alert("asyncLogin");
    // error reading value
  }
}
export const saveUserLogin = (value: any) => {
  try {
    const jsonValue = JSON.stringify(value)
    localStorage.setItem(USERLOGIN, jsonValue)
  } catch (e) {
    alert(e)
  }
}
export const getUserLogin = () => {
  try {
    const value: any = localStorage.getItem(USERLOGIN)
    return JSON.parse(value)
  } catch (e) {
    // alert("asyncLogin");
    // error reading value
  }
}
export const saveUpdateUserProfile = async (value: any) => {
  try {
    const jsonValue = JSON.stringify(value)
    await localStorage.setItem(UPDATEUSERPROFILE, jsonValue)
  } catch (e) {
    alert(e)
  }
}
