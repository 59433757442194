import { useEffect, useState } from "react";
import ButtonComponent from "../components/Button";
import Navbar from "../components/Navbar";
import { useNavigate } from "react-router-dom";
import {
  getUserDetails,
  getUserProfile,
  saveUserProfile,
} from "../storage/storage";
import Options from "../components/Options";
import { apipatchUpdateProfile } from "../service/ApiServices";
//@ts-ignore
import Loader from 'react-js-loader'
import CommonModal from "../components/CommonModal";
import { t } from 'i18next';
import Footer from "../components/Footer";
import { OK } from "../constants/Constants";

export const UpdateProfileScreen = () => {
  const navigate = useNavigate();
  const [gradeDataValue, setGradeDataValue] = useState<any>();
  const [languageDataValue, setLanguageDataValue] = useState<any>();
  const [valueentityDataValue, setValueEntityDataValue] = useState<any>();
  const [userProfile, setUserProfile] = useState<any>();
  const [alertModal, setAlertModal] = useState<any>({ show: false, msg: "" });
  const [userDetails, setUserDetails] = useState<any>([]);
  const [isActive, setIsActive] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const gradeData = [
    {
      value: 7,
      placeholder: t("7thGrade"),
    },
    {
      value: 8,
      placeholder: t("8thGrade"),
    },
    {
      value: 9,
      placeholder: t("9thGrade"),
    },
    {
      value: 10,
      placeholder: t("firstYearHighSchool"),
    },
    {
      value: 11,
      placeholder: t("secondYearHighSchool"),
    },
    {
      value: 12,
      placeholder: t("lastYearHighSchool"),
    },
  ];
  const languageData = [
    {
      value: 1,
      placeholder: "Svenska",
    },
    {
      value: 2,
      placeholder: "English",
    },
    {
      value: 3,
      placeholder: "عربي",
    },
    {
      value: 4,
      placeholder: "Deutsch",
    },
    {
      value: 5,
      placeholder: "Español",
    },
    {
      value: 6,
      placeholder: "中文",
    },
    {
      value: 7,
      placeholder: t("other"),
    },
  ];
  const valueentityData = [
    {
      value: 1,
      placeholder: t("female"),
    },
    {
      value: 1,
      placeholder: t("male"),
    },
    {
      value: 1,
      placeholder: t("nonBinary"),
    },
    {
      value: 1,
      placeholder: t("I'dRatherNotSay"),
    },
  ];

  const onUpdateProfile = () => {
    if (
      gradeDataValue === undefined ||
      languageDataValue === undefined ||
      valueentityDataValue === undefined
    ) {
      setAlertModal({
        show: true,
        msg: "Please answer all required questions.",
      });
    } else {
      setIsLoading(true)
      apipatchUpdateProfile(
        gradeDataValue?.placeholder,
        languageDataValue?.placeholder,
        valueentityDataValue?.placeholder,
        languageDataValue?.value,
        gradeDataValue?.value,
        userDetails?.userId,
        userProfile?.id,
        localStorage.getItem("token")!
      )
        .then((response) => {

          let updateUserProfile = {
            id: response.id,
            grade: response.grade,
            language: response.language,
            gender: response.gender,
            languagecode: response.languagecode,
            gradeId: response.gradeId,
            userId: response.userId,
            token: userDetails.token,
          };
          saveUserProfile(updateUserProfile);
          navigate("/bookGroupRoom");
        })
        .catch((error) => { setIsLoading(false) });
    }
  };

  useEffect(() => {
    const updateUserProfile = () => {
      setGradeDataValue({ placeholder: userProfile?.grade, value: userProfile?.gradeId });
      setLanguageDataValue({ placeholder: userProfile?.language, value: userProfile?.languagecode });
      setValueEntityDataValue({ placeholder: userProfile?.gender });
    };
    updateUserProfile();
  }, [userProfile]);

  useEffect(() => {
    const res = getUserProfile();
    if (res) {
      setUserProfile(res);
    }
    const res1 = getUserDetails();

    if (res1) {
      setUserDetails(res1);
    }
  }, []);


  return (
    <div className="bgImage">
      <Navbar />
      <div className="card MyProfileCard mx-auto">
        <p className="cardHeading">{t("updateProfileTitle")}</p>
        <Options
          question={t("imGrade")}
          data={gradeData}
          onpress={setGradeDataValue}
          value={gradeDataValue}
        />
        <Options
          question={t("myNativeLanguage")}
          data={languageData}
          onpress={(item: any) => {
            setLanguageDataValue(item);
            setIsActive(true);
          }}
          value={languageDataValue}
          userProfile={userProfile?.language}
          isActive={isActive}
        />

        <Options
          question={t("iIdentifyAs")}
          data={valueentityData}
          onpress={(item: any) => {
            setValueEntityDataValue(item);
            setIsActive(true);
          }}
          value={valueentityDataValue}
          userProfile={userProfile?.gender}
          isActive={isActive}
        />
        <div id="buttonContainer">
          {isLoading && (
            <div className="preload-content">
              <Loader type="bubble-top" bgColor={'#005190'} size={40} />
            </div>
          )}
          <ButtonComponent BtnText={t("Update")} Onclick={onUpdateProfile} />
        </div>
        
      </div>
      {alertModal.show && (
        <CommonModal
          title={alertModal.msg}
          onClose={() => setAlertModal({ show: false, msg: "" })}
          buttonText={OK}
        />
      )}
      <Footer />
    </div>
  );
};
export default UpdateProfileScreen;
