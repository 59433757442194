import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import logo from '../assets/images/logo.png'
import NavDropdown from 'react-bootstrap/NavDropdown'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { navigation } from '../constants/Constants'
import { t } from 'i18next';
import { getUserProfile } from '../storage/storage'
function NavbarComponent() {
  const navigate = useNavigate()
  let location = useLocation()
  const onRemove = () => {
    localStorage.clear()
    navigate('/')
  }
  const OnUpdateProfile = () => {
    const res = getUserProfile()
    if (res == null) {
      navigate("/myProfile");
      alert(t("CompleteYourProfileIst"));
    }
    else if (!res.grade && !res.language && !res.gender && !res.userId) {
      navigate("/myProfile");
      alert(t("CompleteYourProfileIst"));
    }
    else {
      navigate('/updateProfile')
    }
  }
  return (
    <Navbar expand="lg">
      <Container className="navOuter">
        <Link to={"/bookGroupRoom"}>
          <img src={logo} alt='' className="logo" />
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse className="justify-content-end" id="basic-navbar-nav">
          <Nav>
            {navigation.map((item: any) => (
              <Link
                to={item.route}
                state={{isFromNavBar: true}}
                className={`nav-link ${location.pathname === item.route ? 'activeNav' : ''
                  }`}
              >
                {t(item.name)}
              </Link>
            ))}
            <NavDropdown title={t("myProfile")} id="basic-nav-dropdown">
              <NavDropdown.Item
                className="DropdownItem"
                href="#"
                onClick={() => OnUpdateProfile()}
              >
                {t("updateProfile")}
              </NavDropdown.Item>
              <NavDropdown.Item
                className="DropdownItem"
                href="#"
                onClick={() => onRemove()}
              >
                {t("logOut")}
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}
export default NavbarComponent
