import moment from 'moment-timezone'

const getDate = () => {
    // let date = new Date()
    const localTimeZone = moment.tz.guess();
   return fetch(`https://worldtimeapi.org/api/timezone/${localTimeZone}`)
    .then(response => response.json())
    .then(data => {
      const utcTime = data.utc_datetime;
    //   date = data.datetime
      
      console.log(data.datetime); // UTC time in ISO format
      return data.datetime
    })
    .catch(error => {
        // date = new Date()
        console.error('Error fetching UTC time:', error)
        return new Date()
    });
    // return date
}

export default getDate
