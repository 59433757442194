import { t } from "i18next";
import CrossIcon from "../assets/images/crossIcon.png"

type Props = {
  title: string;
  onClose: () => void;
  list?: []
  className?: string;
  buttonText?: string;
}

type objectType = {
  text: string
}

function CommonModal({ title, onClose, list, className, buttonText }: Props) {
  return (
    <div className='modalOverlay' onClick={onClose}>
      <div className={`modalInner ${className}`} onClick={(e) => e.stopPropagation()}>
        <div className='crossIconContainer' onClick={onClose}>
          <img src={CrossIcon} alt="CrossIcon" />
        </div>
        <h3>{title}</h3>
        <ul className='list'>
          {list?.map((item: objectType, index: number) => {
            return (
              <li key={index}>{t(item.text)}</li>
            )
          })}
        </ul>
        <button className='button mt-3 mx-auto okButton' onClick={onClose}>{buttonText}</button>
      </div>
    </div>
  )
}

export default CommonModal