import { useEffect, useRef, useState } from "react";
import Navbar from "../components/Navbar";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { useLocation, useNavigate } from "react-router-dom";

import {
  getBookingRoom,
  getUserProfile,
  getUserLogin,
  getCreateRoom,
} from "../storage/storage";
import {
  apiPostGetJoinSession,
  apipatchUserBookingStatus,
} from "../service/ApiServices";
import useConfig from "../hooks/useConfig";
import { OK } from '../constants/Constants';
import { t } from 'i18next';
import Room from "../components/Room";
import CommonModal from "../components/CommonModal";
import EdgarBigLogo from "../assets/images/edgarLogo.svg";
import EdgarLogo from "../assets/images/edgarLogoBlack.svg";
import moment from "moment";
import getDate from "../useDate";

export const EnteringRoomScreen = (route: any) => {

  const { state } = useLocation()
  let item = localStorage.getItem("sessionCode");
  let createGroupRoom = localStorage.getItem("createGroup");
  let roomIdentity = localStorage.getItem("identity");
  let roomId = localStorage.getItem("id");
  let remainingTime = localStorage.getItem("RemainingTime");

  const navigate = useNavigate();
  const [sessionCode, setSessionCode] = useState<string>();
  const [userProfile, setUserProfile] = useState<string>();
  const [createRoom, setCreateRoom] = useState<any>();
  const imageRef = useRef<HTMLInputElement>(null);
  const [alertModal, setAlertModal] = useState<any>({ show: false, msg: "" });
  const [timeMilliSec, setTimeMilliSec] = useState<number | null>(null);
  const [joined, setJoined] = useState(false);
  const [userLogin, setUserLogin] = useState<any>();
  const {
    token,
    getToken,
  } = useConfig();
  let mobile = window.matchMedia("(min-width: 0px) and (max-width: 992px)");


  useEffect(() => {
    (async() => {
      let sessiontime = state?.sessionTime;
      let currenttime = moment(await getDate()).format("YYYY-MM-DD HH:mm:ss")
          let SelectedDate = moment(await getDate()).format("YYYY-MM-DD");
          let sessionTime = moment(SelectedDate + sessiontime, "YYYY-MM-DD HH:mm:ss");
          var duration: any = moment.duration(sessionTime.diff(currenttime));
          var seconds = parseInt(duration.asSeconds());
          setTimeMilliSec(seconds)
        })()
  }, [])

  useEffect(() => {
    joinRoom();
  }, []);

  useEffect(() => {
    if (imageRef.current != null) {
      if (mobile.matches) {
        imageRef.current.src = EdgarLogo;
      } else imageRef.current.src = EdgarBigLogo;
    }
  }, []);

  const questionList = [
    { question: "What math topics are you studying right now?" },
    { question: "Is there anything in math that you find difficult?" },
    { question: "What resources do you use to help you with math?" },
    { question: "Do you have any study tips or strategies for math?" },
  ];
  useEffect(() => {
    window.addEventListener("online", () => {
      setAlertModal({
        show: false,
        msg: "",
      })
      if(joined) joinRoom()
      
    });

    window.addEventListener("offline", () => {
      localStorage.setItem("connectionLost", "true");
      setAlertModal({
        show: true,
        msg: "your internet connection is lost",
      });
    });
    
    const res1 = getUserProfile();
    if (res1) {
      setUserProfile(res1);
    }
    const res2 = getCreateRoom();
    if (res2) {
      setCreateRoom(res2);
    }

    const res3 = getUserLogin();
    if (res3) {
      setUserLogin(res3);
    }
    getBookingRoom();
  }, []);
  const cancelStatus = () => {
    apipatchUserBookingStatus(
      userProfile,
      roomId,
      "3",
      localStorage.getItem("token")!
    ).then((response) => {
      navigate("/myBookings");
    });
  };

  const joinRoom = () => {
    if (createGroupRoom == "true") {
      getToken({
        identity: roomIdentity, roomName: item
      });
      setJoined(true);
    } else {
      if (userProfile) {
        apiPostGetJoinSession(
          userProfile,
          roomId,
          localStorage.getItem("token")!
        ).then((response) => {
          if (response?.session?.SessionStatus == "true") {
            getToken({
              identity: roomIdentity,
              roomName: response?.session?.SessionCode,
            });
            setSessionCode(response?.session?.SessionCode);
            setJoined(true);
          }
          else {
            apipatchUserBookingStatus(
              userProfile,
              roomId,
              "3",
              localStorage.getItem("token")!
            ).then((response) => { navigate("/bookNextRoom") })

          }
        });
      }
    }
  };
  const copyInviteCode = () => {
    navigator.clipboard.writeText(
      createRoom?.roomData?.inviteCode
        ? "Hi, Please login to https://edgareducation.com and join the room with this invite code: " +
        createRoom?.roomData?.inviteCode
        : ""
    );
  };

  const disconnectVideo = () => {
    if(joined) apipatchUserBookingStatus(
      userProfile,
      roomId,
      "2",
      localStorage.getItem("token")!
    ).then((response) => {
      localStorage.removeItem("createRoom");
      navigate("/myBookings");
      setJoined(false);
      if (localStorage.getItem('internetLost') === 'true') {
        localStorage.removeItem("internetLost")
        // window.location.reload()
      }
    })
    // else window.location.reload()
  };

  const renderTime = (dimension: string, time: string | number) => {
    return (
      <div className="time-wrapper">
        <div>{time}</div>
        <div className="timeStatus">{dimension}</div>
      </div>
    );
  };

  const children = ({ remainingTime }: { remainingTime: number }) => {
    const minutes = Math.floor(remainingTime / 60)
    const seconds = remainingTime % 60
    if (minutes === 0) return renderTime('Seconds', remainingTime)
    else if (seconds < 10) return renderTime('Minutes', `${minutes}:0${seconds}`)
    else return renderTime('Minutes', `${minutes}:${seconds}`)
  }


  return (
    <>
      {state?.isFromNavBar === undefined && !joined ? timeMilliSec === null ? null : (
        <>
          <div className="bgImage" id="enteringRoomBg">
            <Navbar />

            <div
              className={`BookingCards_Outer container ${localStorage.getItem("creatGroup") == "false"
                ? "justify-content-between"
                : "justify-content-center"
                } `}
            >
              <>
                <div className="card roomBookingCard MyProfileCard myBooking joinClassroom_Card">
                  <p className="cardHeading">{t("getReady")}</p>
                  <p className="questionsTxt roomText">
                    {t("theRoomOpenAutomatically")}
                  </p>
                  <div
                    style={{ alignSelf: "center" }}
                    className="CountDownCircle"
                  >
                    <svg style={{ position: "absolute", top: "20px" }}>
                      <defs>
                        <linearGradient
                          id="Gradient"
                          x1="0.9"
                          y1="0"
                          x2="-0.4"
                          y2="0"
                        >
                          <stop offset="10%" stopColor="#FFAC38" />
                          <stop offset="23%" stopColor="#F53A3B" />
                          <stop offset="67%" stopColor="#3B62B4" />
                        </linearGradient>
                      </defs>
                    </svg>
                    <CountdownCircleTimer
                      size={280}
                      isPlaying
                      duration={timeMilliSec < 1 ? 10 : timeMilliSec}
                      onComplete={() => {
                        joinRoom();
                      }}
                      rotation="counterclockwise"
                      colors="url(#Gradient)"
                    >
                      {children}
                    </CountdownCircleTimer>
                  </div>
                  <button
                    className="cancelButton mt-5"
                    style={{ alignSelf: "center" }}
                    onClick={() => cancelStatus()}
                  >
                    {t("leave")}
                  </button>
                </div>
                {localStorage.getItem("creatGroup") == "false" && (
                  <div className="todayBookingContainer ">
                    <img src={EdgarBigLogo} alt="LOGO" ref={imageRef as any} />
                    <div className="card roomBookingCard MyProfileCard enteringRoomCard joinClassroom_Card w-100">
                      <h4 className="cardHeading">{t("helloThere")}</h4>
                      <p className="hereText">
                        {t("hereFewQuestions")}
                      </p>
                      <ul className="questionList">
                        {questionList.map((item: any) => {
                          return (
                            <li className="questionsTxt Questions">
                              {item.question}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                )}
              </>
            </div>
          </div>
        </>
      ) : (
        <div className="bgImage" id="videoBg">
          <Room
            token={token}
            identity={roomIdentity}
            roomName={sessionCode ?? item}
            onDisconnected={disconnectVideo}
            copyText={copyInviteCode}
            inviteCode={createRoom?.roomData?.inviteCode}
            userLogin={userLogin?.picture}
            remainingSessionTime={remainingTime}
            createGroupRoomScreen={createGroupRoom}
          />
        </div>
      )}
      {alertModal.show && (
        <CommonModal
          title={alertModal.msg}
          onClose={() => {
            setAlertModal({ show: false, msg: "" });
            disconnectVideo();
          }}
          buttonText={OK}
        />
      )}
    </>
  );
};
export default EnteringRoomScreen;
